import { colors, StyledIcon } from 'fitify-ui'
import styled, { css } from 'styled-components'

import { StyledInputProps } from '@/components/forms/Input/Input.Types'

export const StyledInput = styled.input<StyledInputProps>`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  appearance: textfield;
  padding: 0;
  border: 0;
  outline: none;
  background: inherit;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }
  &::placeholder {
    color: ${colors.Gray500};
  }
`

const autoFillBackground = (backgroundColor: string) => css`
  ${StyledInput} {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px ${backgroundColor} inset;
    }
  }
`

export const StyledInputWrapper = styled.div<StyledInputProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
  font-family: 'Graphik LCG Web', sans-serif;
  line-height: 1;

  ${({ $hasError, $isDisabled, $variant }) => {
    switch ($variant) {
      case 'filled': {
        return css`
          padding: 8px;
          font-weight: 500;
          color: ${colors.Black};
          background: ${colors.White};
          border-radius: 4px;
          border: 1px solid ${$hasError ? colors.Red400 : colors.Gray200};
          ${autoFillBackground(colors.White)};

          &:focus-within {
            border: 1px solid ${$hasError ? colors.Red400 : colors.Gray500};
          }
        `
      }
      case 'filled-gray': {
        return css`
          padding: 8px;
          color: ${colors.Black};
          background: ${colors.Gray200};
          border-radius: 4px;
          border: ${$hasError ? `1px solid ${colors.Red400}` : 'none'};
          ${autoFillBackground(colors.Gray200)};
        `
      }
      case 'transparent': {
        return css`
          padding: 0;
          color: ${colors.Black};
          background: transparent;
          outline: none;
          ${autoFillBackground('transparent')};
        `
      }
      case 'card': {
        return css`
          padding: 0;
          color: ${colors.Gray600};
          background: transparent;
          outline: none;
          ${autoFillBackground('transparent')};

          ${StyledInput} {
            color: ${$hasError ? colors.Red400 : colors.Gray600};

            &:focus {
              color: ${colors.Black};
            }
          }
        `
      }
      case 'underline':
      default: {
        return css`
          padding: 0 8px;
          color: ${$isDisabled ? colors.Gray600 : colors.Black};
          background: ${$isDisabled ? colors.Gray50 : 'transparent'};
          border-bottom: 1px solid ${$hasError ? colors.Red400 : colors.Gray200};
          outline: none;
          ${autoFillBackground('transparent')};

          &:focus-within {
            border-bottom: 1px solid
              ${$hasError ? colors.Red400 : colors.Gray500};
          }
        `
      }
    }
  }};

  ${({ $variant, $variantSize }) => {
    if ($variant === 'card') {
      return css`
        height: 20px;
        font-size: 14px;
        line-height: 1.4;
      `
    }
    switch ($variantSize) {
      case 'xs': {
        return css`
          height: 12px;
          font-size: 10px;
        `
      }
      case 'lg': {
        return css`
          height: 32px;
          font-size: 12px;
        `
      }
      case 'md':
      default: {
        return css`
          height: 24px;
          font-size: 11px;
        `
      }
    }
  }};
`

export const StyledIconWrapper = styled.div<
  Pick<StyledInputProps, '$variantSize'>
>`
  display: flex;
  flex: 0 0 auto;

  ${StyledIcon} {
    color: ${colors.Gray500};
  }

  ${({ $variantSize }) => {
    switch ($variantSize) {
      case 'xs': {
        return css`
          ${StyledIcon} {
            width: 8px;
            height: 8px;
          }
        `
      }
      case 'lg':
      case 'md':
      default: {
        return css`
          ${StyledIcon} {
            width: 10px;
            height: 10px;
          }
        `
      }
    }
  }};
`

export const StyledSuffix = styled.div`
  display: flex;
  flex: 0 0 auto;
`
